/**
 * This method will let the browser decide on when to execute
 * the functions given within the parameter array.
 *
 * @param {function[]} funcArray An array of functions that should be called
 *                               whenever there is time.
 * @return {undefined}
 */
export function callFuncsAsync(funcArray) {
    funcArray.forEach(func => window.setTimeout(func, 0));
}

/**
 * Executes a function a max of once every n milliseconds
 *
 * @method throttle
 * @static
 *
 * @param {Function}    func     Function to be throttled.
 * @param {Number}      delay    Function execution threshold in milliseconds.
 *
 * @return {Function} Function with throttling applied.
 */
export function throttle(func, delay) {
    var timer = null;

    return function throttler() {
        const args = arguments;

        if (timer === null) {
            timer = window.setTimeout(() => {
                func.apply(null, args);
                timer = null;
            }, delay);
        }
    };
}

/**
 *  Merge two objects, creating a new object containing all the
 *  properties of both.
 *
 *  @method mergeObjects
 *  @static
 *
 *  @param  {Object}    obj1    The first object.
 *  @param  {Object}    obj2    The second object.
 *  @param  {Boolean}   overwrite   If true, properties of the second object
 *                                  will overwrite the properties of the first.
 *                                  If false, properties of the first will not
 *                                  be overwritten.  Defaults to `true`.
 *
 *  @return {Object}    The new, merged object
 */
export function mergeObjects(obj1, obj2, overwrite = true) {
    const obj = {};
    let key;

    for (key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            obj[key] = obj1[key];
        }
    }
    for (key in obj2) {
        if (obj2.hasOwnProperty(key) && (obj[key] === undefined || overwrite)) {
            obj[key] = obj2[key];
        }
    }
    return obj;
}
