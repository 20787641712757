/* global window, Image, require, VALITON_WA */

import statList from './configuration.json';

/**
 * Name of the attribute holding the linktrack-information
 */
const LINKTRACK_ATTRIBUTE = 'data-linktrack';

/**
 * Name of the attribute holding the linktrack-options
 */
const LINKTRACK_OPTIONS_ATTRIBUTE = 'data-linktrack-options';

/**
 * Button-number returned by the mouse event if the middle mouse button was pressed
 */
const MIDDLE_MOUSE_BUTTON_IDENTIFIER = 1;


/**
 * whether or not the tracking was already enabled
 */
var ENABLED = false;

/**
 * Helper-function for setting the enabled-status
 *
 * @param {Boolean} status boolean indicating the enabling-status of the tracker
 * @returns {undefined}
 */
function setEnabled(status) {
    ENABLED = status;
}

/**
 * Helper-function for getting the enabled-status
 *
 * @returns {boolean} true if it was already enabled, false otherwise
 */
function isEnabled() {
    return ENABLED;
}

/**
 * Creates an replacement-object containing a template string that will be replaced by the replacement-string
 *
 * @param {String} template This will be searched in the examined string
 * @param {String} replacement This will be the replacement value of the template
 * @returns {{template: *, replacement: *}} Object containing the passed data in a predefined structure
 */
function createReplacement(template, replacement) {
    return {
        template,
        replacement
    };
}

/**
 * Replaces a List of placeholders inside a string
 *
 * @param {String} replacementString The String that will be basis for the replacement
 * @param {Array} placeholderList a list of Replacements in the format {{template: String, replacement: String}}
 * @returns {String} The string with all occurances of the passed templates replaced
 * with their according replacement-string
 */
function replacePlaceholders(replacementString, placeholderList) {
    var replacedString = replacementString;

    for (var index = placeholderList.length - 1; index >= 0; index--) {
        var placeholder = placeholderList[index];

        replacedString = replacedString.replace(placeholder.template, placeholder.replacement);
    }
    return replacedString;
}

/**
 * Checks whether or not the given node has a data-linktrack attribute
 *
 * @param {Object} node to check against
 * @returns {*} false if it is not present, true otherwise
 */
function hasDataLinktrackAttribute(node) {
    // noinspection JSValidateTypes
    return typeof node.getAttribute === 'function' && node.getAttribute(LINKTRACK_ATTRIBUTE) !== null;
}

/**
 * Replaces the trackName with a list of substitutions.
 *
 * @param {String} trackName - original trackName that will be partly replaced if needed
 * @param {Object[]} replacements - List of Replacements; data defines an attribute that holds the desired data
 * @param {Element} dataNode - Element that holds all the replacement information
 * @returns {*} - trackName
 */
function replaceTrackName(trackName, replacements, dataNode) {
    var replacedTrackName = trackName;

    try {
        for (var replacementIndex = replacements.length - 1; replacementIndex >= 0; replacementIndex--) {
            var replacement = replacements[replacementIndex];
            var name = replacement.name;
            var data = dataNode.getAttribute(replacement.data);

            replacedTrackName = replacedTrackName.replace(name, data || name);
        }
    } catch (ex) {
        // ignore, just return the original trackName
    }
    return replacedTrackName;
}

/**
 * Processes additional pixels which are defined inside the configuration
 *
 * @param {Array} pixelList - List of additional pixels
 * @param {Array} replacements - List of replacements
 * @param {Element} rootNode - Node to start looking for elements
 * @returns {undefined}
 */
function processAdditionalPixels(pixelList, replacements, rootNode) {
    for (var pixelIndex = pixelList.length - 1; pixelIndex >= 0; pixelIndex--) {
        var pixel = pixelList[pixelIndex];

        pixel.options = {
            replacements
        };
        processTrackingPixel(pixel, rootNode, rootNode); // eslint-disable-line no-use-before-define
    }
}

/**
 * Uses the information provided and creates a linktrack-attribute.
 *
 * @param {{identifier: String, trackName: String, options: Object}} trackingObject - Information on one pixel
 * @param {Element} dataNode - Node that holds data-information
 * @param {?Element} root - Startingpoint from where the nodes will be searched (document by default)
 * @returns {undefined}
 */
function processTrackingPixel(trackingObject, dataNode, root) {
    const startingPoint = root || document;
    const elementList = startingPoint.querySelectorAll(trackingObject.identifier);
    const options = trackingObject.options || {additionalPixels: [], replacements: []};
    const trackName = replaceTrackName(trackingObject.trackName, options.replacements, dataNode);

    for (let elementIndex = elementList.length - 1; elementIndex >= 0; elementIndex--) {
        const element = elementList[elementIndex];

        if (trackName && !element.hasAttribute(LINKTRACK_ATTRIBUTE)) {
                element.setAttribute(LINKTRACK_ATTRIBUTE, trackName);
        }
        if (options.additionalPixels && options.additionalPixels.length > 0) {
            processAdditionalPixels(options.additionalPixels, options.replacements, element);
        }
    }
}

/**
 * Uses the JSON parsed from the tracking-configuration and processes the different pixels.
 * This can be used to trigger subsequent creation of tracking-data after insertion of new HTML-data
 *
 * @returns {void}
 */
function processTrackingConfiguration() {
    for (let index = statList.length - 1; index >= 0; index--) {
        const trackingElement = statList[index];

        if (trackingElement.identifier) {
            processTrackingPixel(trackingElement, trackingElement, null);
        }
    }
    addTopThemeTracking();
}

/**
 * Selectors explained:
 * - .sz-page__main-column > .teaserlist a - Selects all teaser links in the main column of pages already rendered in play.
 * - .mainpage > .teaserlist a             - Selects all teaser links in the maincolumn of the homepage. Will not be needed anymore
 *                                           once the homepage is completely rendered in play.
 * - .sz-content__top-teasers .teaserlist a - Selects all teaser links in the maincolumn of the new homepage.
 * ...                                     - The rest is used for the pagetemplates. Can also be removed once they are rendered
 *                                           in play.
 */
function addTopThemeTracking() {
    const allLinks = document.querySelectorAll('.sz-page__main-column > .teaserlist a, .sz-content__top-teasers .teaserlist a, .mainpage > .teaserlist a, #sitecontent.mainpage .teaser > a, #sitecontent.mainpage .teaser .oneliner li > a');
    const payLinks = Array.from(document.querySelectorAll('.teaserlist .interactiveeditorial a'));

    // links = allLinks - links // if we could use sets
    const links = [];
    for (const link of allLinks) {
        if (link === payLinks[0]) {
            payLinks.shift();
        } else {
            links.push(link);
        }
    }

    for (let i = 0; i < links.length; i++) {
        links[i].setAttribute(LINKTRACK_ATTRIBUTE, `onclick_platformtype_homepage_topthema_${i + 1}`);
    }
}

/**
 * Returns the options saved in JSON-format inside of a nodes data-linktrack-options attribute
 *
 * @param {Object} node The node which holds possible options
 * @returns {Object} a JSON-object containing the options of this data-linktrack-instance
 * or null if none were found.
 */
function getLinktrackOptions(node) {
    try {
        return JSON.parse(node.getAttribute(LINKTRACK_OPTIONS_ATTRIBUTE));
    } catch (err) {
        return null;
    }
}

/**
 * Returns an option of the linktrack-options saved on the node.
 *
 * @param {Object} node The node one is currently interacting with
 * @param {String} key the key inside the options one wants to access
 * @param {String} defaultValue The value that will be returned if no valid option could be associated with the key
 * @returns {*} the options-value fitting the key or the defaultvalue otherwise
 */
function getOption(node, key, defaultValue) {
    try {
        return getLinktrackOptions(node)[key] || defaultValue;
    } catch (err) {
        return defaultValue;
    }
}

/**
 * Finds a node that has a data-linktrack attribute set in the chain of parents of the currently clicked node
 *
 * @param {Object} node that was clicked by the user
 * @returns {Array} A List of nodes with the data-linktrack attribute in the parental-chain
 *          of nodes or an empty array if none was found
 */
function findLinktrackNodes(node) {
    var nodeList = [];
    var currentNode = node;

    while (currentNode.parentNode && currentNode.parentNode !== document.body) {
        if (hasDataLinktrackAttribute(currentNode)) {
            nodeList.push(currentNode);
        }
        currentNode = currentNode.parentNode;
    }
    return nodeList;
}

/**
 * Combines all linktrack-names of one node into an array
 *
 * @param {Object} node the node one wants to get the linktrack-names of
 * @return {Array} Combination of the data-linktrack-value and possible additionalNames inside the
 *  data-linktrack-options-attribute
 */
function getTrackingNames(node) {
    var linkTrackName = node.getAttribute(LINKTRACK_ATTRIBUTE);
    var combinedLinkTrackNames = getOption(node, 'additionalNames', []);

    combinedLinkTrackNames.push(linkTrackName);
    return combinedLinkTrackNames;
}

/**
 * Based on a list of nodes with data-linktrack attributes, this function collects all trackingnames and filters
 * the duplicates.
 *
 * @param {Array} nodeList List of nodes with data-linktrack attributes
 * @returns {Array} List of linkttrack-names
 */
function gatherLinkTrackNames(nodeList) {
    var combinedLinkTrackNames = [];

    for (var nodeIndex = nodeList.length - 1; nodeIndex >= 0; nodeIndex--) {
        combinedLinkTrackNames = combinedLinkTrackNames.concat(getTrackingNames(nodeList[nodeIndex]));
    }
    return combinedLinkTrackNames;
}

/**
 * Checks if any mouse modifier was pressed (like the ctrl- or cmd-key) or the middle-button was used on the mouse.
 * If so, we assume, the user wants to open the link in a new tab and we set the target accordingly
 *
 * @param {MouseEvent} event The mouse-event triggering the handler
 * @returns {String} _blank as the target for the link if our condition was met, null otherwise
 */
function getMouseModifierTarget(event) {
    var middleMouseButtonPressed = event.button === MIDDLE_MOUSE_BUTTON_IDENTIFIER;
    var modifierKeyPressed = event.ctrlKey || event.metaKey;

    if (middleMouseButtonPressed || modifierKeyPressed) {
        return '_blank';
    }
    return null;
}

/**
 * Opens the target url either in the current window or in the 'target' window.
 *
 * When clicking on a link, we prevent the redirect until we are done with the click tracking.
 * This method is used to navigate to the intended url after we are done with tracking.
 *
 * @param {String} target The target of the URL (typically the same tab/window or _blank)
 * @param {String} targetUrl The target-URL that will be called after tracking
 * @param {Boolean} followTarget whether or not a associated target should be visited afterwards
 * @returns {undefined}
 */
function openLink(target, targetUrl, followTarget) {
    if (!targetUrl || !followTarget) {
        return;
    }

    if (target) {
        window.open(targetUrl, target);
    } else {
        window.location = targetUrl;
    }
}

/**
 * Set tracking pixel for SiteStat
 *
 * @param {String} name             name of the clicktracking
 * @param {String} targetUrl        link target (only if we have an 'a' tag as element node)
 * @param {String} target           the target of the link, i.e. _blank
 * @param {Boolean} followTarget    if the href of the Link should be followed
 * @returns {undefined}
 */
function track(name, targetUrl, target, followTarget) {
    const headerStyle = document.querySelector('.mainnav.sticky') ? 'small' : 'big';
    const platform = document.querySelector('#flying-menu') ? 'mew' : 'desktop';
    const nameReplacements = [
        createReplacement('platformtype', platform),
        createReplacement('header_style', headerStyle)
    ];

    const replacedName = replacePlaceholders(name, nameReplacements);
    const trackObject = {headerStyle, platform};

    trackObject.event = 'comscore';
    trackObject.eventCategory = 'comscore';
    trackObject.eventAction = 'click';
    trackObject.eventLabel = replacedName;
    if (window.dataLayer) {
        window.dataLayer.push(trackObject);
    }

    window.setTimeout(() => {
        openLink(target, targetUrl, followTarget);
    }, 100);
}

/**
 * Creates trackingpixels for the list of trackNames and jumps to the targetUrl afterwards if any is given
 *
 * @param {Array} combinedLinkTrackNames Array of trackNames
 * @param {Object} targetUrl URL to jump to after creating all pixels
 * @param {Object} target the target of the link, i.e. _blank
 * @returns {undefined}
 */
function processLinkTrackNames(combinedLinkTrackNames, targetUrl, target) {
    for (var index = combinedLinkTrackNames.length - 1; index >= 0; index--) {
        var name = combinedLinkTrackNames[index];
        var followTarget = index === 0;

        track(name, targetUrl, target, followTarget);
    }
}

/**
 * Handler for the clickelements on the document. It will try to get all nodes in the parental hierarchy
 * of the clicked element that have a linktrack-attribute set and afterwards tracks all those names.
 * If several data-linktrack elements are available in the parental hierarchy, it will use the link information
 * (targetUrl and target) of the first node, assuming that the user tends to click the link directly.
 *
 * @param {Event} e Event that was fired
 * @returns {void}
 */
function handleClick(e) {
    var nodeList = findLinktrackNodes(e.target);

    if (nodeList.length > 0) {
        var combinedLinkTrackNames = gatherLinkTrackNames(nodeList);
        var firstNode = nodeList[0];
        var followTarget = getOption(firstNode, 'followTarget', true);
        var targetUrl = firstNode.getAttribute('href') || firstNode.getAttribute('data-clickurl');
        var target = firstNode.getAttribute('target') || getMouseModifierTarget(e);

        var redirect = targetUrl && followTarget;
        if (redirect) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }

        processLinkTrackNames(combinedLinkTrackNames, targetUrl, target);
    }
}

function configureExternalTracking() {
    if (!isEnabled()) {
        processTrackingConfiguration();
        setEnabled(true);
    }
}

function trackValiton(trackingData) {
    if (typeof VALITON_WA === 'undefined') {
        console.warn("Valiton not available for tracking.");
    } else {
        VALITON_WA.track(trackingData);
    }
}

export default {
    processTrackingConfiguration,
    track,
    trackValiton
};


/**
 * Adding the listener for all clicks and setting the service to 'enabled' to avoid doubled instantiation
 *
 * @returns {void}
 */
export function initStats() {
    function setUpTracking() {
        if ((document.readyState === 'interactive' || document.readyState === 'complete')) {
            document.body.addEventListener('click', handleClick);
            configureExternalTracking();
        }
    }

    setUpTracking();
    document.addEventListener('readystatechange', setUpTracking);
}
