/* globals window */

import {callFuncsAsync} from './helpers';
import {initIe11UpdateNote} from "./init/ie11UpdateNote";
import SdeTrack, {initStats} from './stats/stats';
import SZHeader from './SZHeader';
import USS from './init/uss';

// We need the SdeTrack object because in some cases the tracking is done using the SdeTrack.track() method
// instead of using data-linktrack or the tracking-configuration.
window.SdeTrack = SdeTrack;

function initDesktop() {
    callFuncsAsync([
        initStats
    ]);

    initIe11UpdateNote();

    SZHeader.initialized();
}

if (document.readyState === 'interactive' || document.readyState === 'complete') {
    initDesktop();
} else {
    window.addEventListener('DOMContentLoaded', () => {
        initDesktop();
    });
}
