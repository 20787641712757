// Injects markup to give IE11 users a note to update their browser.

// https://stackoverflow.com/a/21825207
const isIe11 = !!window.MSInputMethodContext && !!document.documentMode;
const containerClassname = 'ie11-update-note';

const createStyles = (className = containerClassname) => {
    const style = document.createElement('style');
    style.innerHTML =
        '.' + className + ' {' +
            'background: #f2f2f3;' +
            'border-bottom: 6px solid #ff4c4c;' +
            'font-size: 14px;' +
            'letter-spacing: 0.015em;' +
            'line-height: 1.5;' +
            'padding: 20px;' +
            'position: relative;' +
            'z-index: 10000;' +
        '}' +

        '.' + className + ' p {' +
            'margin: auto;' +
            'max-width: 1000px;' +
        '}' +

        '.' + className + ' strong {' +
            'font-size: 17px;' +
        '}' +

        '.' + className + ' a {' +
            'color: inherit;' +
            'text-decoration: underline;' +
        '}';

    return style;
}

const createContainer = (styles, className = containerClassname) => {
    const container = document.createElement('div');
    container.classList.add(className);
    container.innerHTML = '<p><strong>Ihr Browser (Internet Explorer 11) ist veraltet und gilt als unsicher.</strong><br>Um SZ.de weiterhin problemlos nutzen zu können, laden Sie bitte einen aktuellen, schnellen und sicheren Browser herunter: <a href="https://www.mozilla.org/firefox/new/">Firefox</a>, <a href="https://www.google.com/chrome/browser/desktop/">Chrome</a>, <a href="https://www.microsoft.com/edge">Edge</a>.</p>';
    // add styles to container
    container.insertBefore(styles, container.firstChild);

    return container;
}

export const initIe11UpdateNote = () => {
    if (!isIe11) {
        return;
    }

    const styles = createStyles();
    const container = createContainer(styles);

    // add container to body element
    document.body.insertBefore(container, document.body.firstChild);
}
