/* globals $, dataLayer */

import axios from 'axios';

let ussUrl;
let loginState;
const callbacks = [];
let stateAvailable = false;
let initialized = false;

const redirectUrlMapping = [
    {
        pathRegex: '^/adblock$',
        getRedirectUrl: () => {
            const localStorageRedirectUrl = localStorage.getItem('adblock_redirecturl');

            return localStorageRedirectUrl || `${document.location.protocol}//${document.location.host}`;
        }
    }
];

/**
 * Returns the redirect URL dependent on the current location.
 *
 * @returns {string} Redirect Url
 */
function getRedirectUrl() {
    const pathname = document.location.pathname;

    for (let i = 0; i < redirectUrlMapping.length; i++) {
        const redirectMapping = redirectUrlMapping[i];
        const pathRegex = new RegExp(redirectMapping.pathRegex);

        if (pathname.match(pathRegex)) {
            return typeof redirectMapping.getRedirectUrl === 'function' && redirectMapping.getRedirectUrl();
        }
    }

    // Default: Redirect the user to the page where the login button was pressed
    let redirectUrl = document.location.href;

    // remove the "reduced=true" parameter from the url, keeping other params if any
    const reducedRegex = new RegExp('reduced=true&?');

    redirectUrl = redirectUrl.replace(reducedRegex, '').replace(/[&\?]$/, '');
    return redirectUrl;
}

function getBaseUrl() {
    if (typeof ussUrl === 'undefined') {
        throw new Error('USS is not configured. Must call USS.setup() before any interaction!');
    }

    if (ussUrl.indexOf('//') >= 0) {
        return ussUrl;
    }

    const loc = window.location;

    return `${loc.protocol}//${loc.host}${ussUrl}`;
}

function resolveCallbacks(state) {
    stateAvailable = true;
    callbacks.forEach(callback => callback(state));
}

function handleNotLoggedInUser() {
    loginState = false;
    resolveCallbacks(loginState);
}

function useUserDataApi() {
    window.SZ.UserData.isLoggedIn().then(loggedIn => {
        if(loggedIn) {
            window.SZ.UserData.loginName().then(loginName => {
                loginState = {
                    login: loginName,
                }
                resolveCallbacks(loginState);
            }).catch(() => {
                handleNotLoggedInUser();
            })
        } else {
            handleNotLoggedInUser();
        }
    }).catch(() => {
        handleNotLoggedInUser();
    });
}

function useSubscriptionInfoApi() {
    axios.get(`${window.SZHeader.Config.baseUrl}/v1/subscriptioninfo?user=true`, {
        withCredentials: true
    }).then(response => {
        if(response.data.userIsLoggedIn) {
            loginState = {
                login: response.data.userSubscriptionInfo.userInfo.email,
            }
            resolveCallbacks(loginState);
        } else {
            handleNotLoggedInUser();
        }
    }).catch(() => {
        handleNotLoggedInUser();
    });
}

export default class USS {
    static setup(myUssUrl) {
        ussUrl = myUssUrl;

        if (initialized) {
            return;
        }
        initialized = true;

        this.getLoginState(state => {
            if ('dataLayer' in window) {
                 dataLayer.push({loginState: state, event: 'loginStateAvailable'});
            }
        });
    }

    static getLoginUrl(gateway) {
        let loc = getBaseUrl();
        const target = getRedirectUrl();

        loc += `?next=${encodeURIComponent(target)}`;

        if (gateway) {
            loc += '&gateway=true';
        }

        return loc;
    }

    static getLogoutUrl() {
        const loc = `${getBaseUrl()}/logout`;
        let returnUrl = document.location.href;

        if (returnUrl.indexOf("logout=true") < 0) {
            returnUrl += returnUrl.indexOf('?') < 0 ? '?' : '&';
            returnUrl += 'logout=true';
        }
        return `${loc}?next=${encodeURIComponent(returnUrl)}`;
    }

    static getProfileUrl() {
        let loc = `${getBaseUrl()}/loggedinaction`;

        loc += `?next=${encodeURIComponent(document.location.href)}`;
        return loc;
    }

    static getLoginState(callback) {
        if (stateAvailable) {
            return callback(loginState);
        }

        if (callbacks.length === 0) {
            if(window.SZ && window.SZ.UserData) {
                useUserDataApi()
            } else {
                useSubscriptionInfoApi()
            }
        }
        return callbacks.push(callback);
    }
}
