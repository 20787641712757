import USS from './init/uss';
import {callFuncsAsync} from './helpers';

// because of async loading we have to do the "|| {}" initialization style
const SZHeader = window.SZHeader || {};
window.SZHeader = SZHeader;

SZHeader.Config = SZHeader.Config || {};

// The InitFunctions can be used to register functions that will be called when the header is initialized.
// The header initialization will also initialize the global SZHeader.Lib.USS object which is
// used by javascript code that gets ESI injected by the Interactive Editorial and other pay teams.
SZHeader.InitFunctions = SZHeader.InitFunctions || [];
SZHeader.Lib = SZHeader.Lib || {};
SZHeader.Lib.USS = USS;

let initialized = false;

SZHeader.initialized = () => {
    if (initialized) {
        return;
    }
    callFuncsAsync(SZHeader.InitFunctions);
    SZHeader.InitFunctions = [];
    SZHeader.InitFunctions.push = func => window.setTimeout(func, 0);
    initialized = true;
};

export default SZHeader;
